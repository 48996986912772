import { Wrapper, SectionTitle, SectionSubtitle } from '../styled';

function Tools() {
  return (
    <Wrapper align="center">
      <SectionTitle>Professional Tools bahkan untuk Pemula</SectionTitle>
      <SectionSubtitle>
        Investasi saham bukan hanya untuk kalangan atas. Stockbit dilengkapi
        dengan data finansial dan fitur kelas professional untuk mendukung
        investasi semua orang.
      </SectionSubtitle>
    </Wrapper>
  );
}

export default Tools;
